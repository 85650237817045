* {
    user-select: auto !important;
  }

.button-outline-dark {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: #333;
    border: 2px solid #333;
    border-radius: 0.8rem;
    cursor: pointer;
    padding: 0.6rem 1rem;
    transition: ease-in all 0.15s;
}

.button-outline-dark:hover {
    background-color: #6C5DD3;
    border-color: #6C5DD3;
    color: #ffffff;
}

.div_card {
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
    width: 100%;
    box-shadow: 0rem 0.5rem 1rem rgba(1,1,1,.03)!important;
}

.div_outline {
    border: 1px solid  var(--border-color);
    border-radius: 1rem;
    padding: 1.6rem 2.2rem;
    margin: 20px 10px;
    display: flex;
    align-items: center;
    color: #888;
    font-size: 12px;;
}

.tile{
    background-color: var(--tile-color);
    display: inline-flex;
    border-radius: 1rem;
    border: 1px solid var(--tile-border-color);
    padding: 0.6rem 1.4rem;
    box-shadow: 0rem 0.2rem 0.5rem rgba(1,1,1,.06)!important;
    cursor: pointer;
    transition: ease-in box-shadow 0.15s;
    margin: 10px;
    color: var(--text-color);
}

.tile:hover{
    display: inline-flex;
    box-shadow: 0rem 0.3rem 0.7rem rgba(1,1,1,.12)!important;
}

.tile .edit_icon{
    display: none;
}

.tile:hover .edit_icon{
    display: flex;
}

.tile .delete_icon{
    display: none;
    filter: var(--icon-filter)
}

.tile:hover .delete_icon{
    display: flex;
    filter: var(--icon-filter)
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.capsule{
    font-size: 0.6rem;
    color: var(--muted-text-color);
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    margin-left: -0.2rem;
    background: var(--tile-color);
    margin: 0.2rem;
    padding: 0.2rem 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 50px;
}

.tiny-text{
    font-size: 0.6rem;
    color: var(--muted-text-color);
    flex-wrap: wrap;
    margin-bottom: 0;
}

.link-item{
    cursor: pointer;
}

.link-item:hover{
    color: #6C5DD3;
}

.row-12{
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-md-6{
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .row-12{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .col-md-6{
        width: 100%;
    }
}

.ms-1{
    margin-left: 15px;
}

.me-1{
    margin-right: 15px;
}

.mt-1{
    margin-top: 15px;
}

.mb-1{
    margin-bottom: 15px;
}
.ms-2{
    margin-left: 30px;
}

.me-2{
    margin-right: 30px;
}

.mt-2{
    margin-top: 30px;
}

.mb-2{
    margin-bottom: 30px;
}

.ps-1{
    padding-left: 15px;
}

.pe-1{
    padding-right: 15px;
}

.pt-1{
    padding-top: 15px;
}

.pb-1{
    padding-bottom: 15px;
}
.ps-2{
    padding-left: 30px;
}

.pe-2{
    padding-right: 30px;
}

.pt-2{
    padding-top: 30px;
}

.pb-2{
    margin-bottom: 30px;
}

.FadeIn-Effect{
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.FadeUp-Effect{
    animation: fadeUpAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeUpAnimation {
    0% {
        opacity: 0;
        transform: translateY(10%);

    }
    100% {
        opacity: 1;
        transform: translateY(0%);
     }
}

.FadeRight-Effect{
    animation: fadeRightAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeRightAnimation {
    0% {
        opacity: 0;
        transform: translateX(10%);

    }
    100% {
        opacity: 1;
        transform: translateX(0%);
     }
}

.FadeoutRight-Effect{
    animation: fadeoutRightAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeoutRightAnimation {
    0% {
        opacity: 1;
        transform: translateX(0%);

    }
    100% {
        opacity: 0;
        transform: translateX(10%);
     }
}

.FadeLeft-Effect{
    animation: fadeLeftAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeLeftAnimation {
    0% {
        opacity: 0;
        transform: translateX(-10%);

    }
    100% {
        opacity: 1;
        transform: translateX(0%);
     }
}

.capsule-solid{
    font-size: 10px; 
    margin: 0px;
    border-radius: 0.3rem;
    padding: 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
    border: 0;
}

.mini-loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #6C5DD3;
    width: 34px;
    height: 34px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


.tile {
    border-radius: 1rem;
    border: 1px solid var(--border-color);
    padding: 1rem;
    box-shadow: 0rem 0.2rem 0.5rem rgba(1,1,1,.06)!important;
    cursor: pointer;
    transition: ease-in box-shadow 0.15s;
    margin: 10px;
}

.tile:hover{
    box-shadow: 0rem 0.3rem 0.7rem rgba(1,1,1,.12)!important;
}

.tile .delete_icon{
    display: none;
}

.tile:hover .delete_icon{
    display: inline-flex;
}

#flow-chart div{
    outline: none !important;
}


#exceltemplate {
    padding-top: 12px;;
}

#exceltemplate .item_container {
    display: flex;
    height: 56px;
    align-items: center;
    flex-direction: row;
    z-index: 1050;
}

#exceltemplate .item_container .input p {
    font-size: 12px;
    color: var(--light-color);
    font-weight: 400;
    flex: 1;
}

#exceltemplate .item_container .input span {
    font-size: 12px;
    color: var(--success-color);
    cursor: pointer;
}

#exceltemplate .item_container .item {
    flex: 1;
}

#exceltemplate .item_container .item p {
    margin-top: 0;
    margin-bottom: 0;
}

#exceltemplate .item_container .item p.header {
    font-size: 12px;
    color: #898989;
    font-weight: 600;
    margin: 0;
}

.item_container {
    display: flex;
    height: 56px;
    align-items: center;
    flex-direction: row;
    z-index: 1050;
}

.item_container .field {
    border: 1px solid var(--border-color);
    background: var(--tile-light-color);
    border-radius: 5px;
    padding: 8px 12px;
    margin-right: 10px;
    font-size: 12px;
    color: var(--light-color);
    font-weight: 400;
}

.item_container .input {
    border: 1px solid var(--border-color);
    padding: 8px 12px;
    position: relative;
    align-items: center;
    display: flex;
}

.item_container input {
    border: 1px solid var(--border-color);
    padding: 6px 12px;
    border-radius: 2px;
    margin-right: 30px;
    width: 100%;
    font-size: 12px;
    color: var(--light-color);
    font-weight: 400;
}

.item_container .input p {
    font-size: 12px;
    color: var(--light-color);
    font-weight: 400;
    flex: 1;
}

.item_container .input span {
    font-size: 12px;
    color: var(--primary-color);
    cursor: pointer;
}

.item_container .item {
    flex: 1;
}

.item_container .item p {
    margin-top: 0;
    margin-bottom: 0;
}

.item_container .item p.header {
    font-size: 12px;
    color: #898989;
    font-weight: 600;
    margin: 0;
}

.item_container .item2 {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.item_container .item2 img {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.draghandle {
    width: 14px;
    cursor: pointer;
    height: 14px;
    margin-right: 16px;
}

ul.sortableHandle {
    margin: 0 !important;
    padding: 0 !important;
}

#exceltemplate .item_container .item2 {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#exceltemplate .item_container .item2 img {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

#exceltemplate .addNewField {
    font-size: 10px;
    color: var(--text-color);
    cursor: pointer;
    font-weight: bold;
    border-radius: 6px;
    border: 2px solid var(--text-color);
    padding: 5px 10px;
}

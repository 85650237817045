/* Modal css End */


.black .rdrDateRangePickerWrapper {
   box-shadow: 0 24px 38px rgb(0 0 0 / 35%), 0 10px 10px rgb(0 0 0 / 29%);
}
.black .rdrDefinedRangesWrapper .rdrStaticRange  {
   background-color: #242831;
}
.black .rdrCalendarWrapper {
   background-color: #242831 !important;
}

.black .rdrStaticRange {
   border-color: #242831;
}

.black .rdrStaticRangeLabel {
   color: #fff !important;
}

.black .rdrDateDisplayItem input {
   background-color: #242831;
}

.black .rdrDateDisplayWrapper {
   background-color:rgba(228, 228, 228, 0.1) ;
}

.black .rdrDateDisplayItem {
   border: 1px solid #242831;
}



.black .rdrDayNumber span {
   color: #fff !important;
}

.black .rdrDayPassive span {
   color: rgba(228, 228, 228, 0.3) !important;
}

.black .rdrDefinedRangesWrapper {
   border-right: 1px solid rgba(228, 228, 228, 0.1) !important;
}

.black .rdrDefinedRangesWrapper {
   background-color: #242831 !important;
}

.black .rdrInputRanges {
   border-top: 1px solid rgba(228, 228, 228, 0.1) ;
}
.black .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
   background-color: rgba(228, 228, 228, 0.1);
   color: #fff;
}

.black  .rdrSelected {
   background-color: var(--active-color);
}

.black .rdrMonthAndYearPickers select {
   color: #fff;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}



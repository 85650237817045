.shine {
    background: var(--tile-light-color);
    background-image: var(--css-skeleton);
    background-repeat: no-repeat;
    background-size: 90% 144px;
    display: inline-block;
    opacity: .8;
    position: relative;
    -webkit-animation-duration: 2s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}


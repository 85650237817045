body.light-theme{
    background:#f9fafa;
    color: #000000;
    --primary-color: #6c5dd3;
    --white-color: #fff;
    --background-color: #f9fafa;
    --wrapper-color: #f9fafa;
    --mini-sidebar-color: #ffffff;
    --sidebar-color: #ffffff;
    --sidebar-item-color: #808191;
    --sidebar-icon-color: #8d8d92;
    --border-color:#dddddd;
    --button-outline-color:transparent;
    --button-color:#1B1D21;
    --button-border-color:#333;
    --input-color: #f2f2f2;
    --tile-color: #fff;
    --tile-border-color:#eee;
    --tile-light-color: #f2f2f2;
    --text-color: #000000;
    --heading-color: #333333;
    --muted-text-color: #666;
    --active-text-color: #ffffff;
    --icon-filter: '';
    --css-skeleton: linear-gradient(to right, #dedede 0%, #dfdfdf 20%, #efefef 40%, #eeeeee 100%);
    --translucent-color: rgba(250,250,250,0.8);
    --active-color: #6c5dd3;
}

body.dark-theme{
    --button-border-color:#333;
    background:#111111;
    color: #ffffff;
    --white-color: #fff;
    --primary-color: #6c5dd3;
    --background-color: #111111;
    --wrapper-color: #111111;
    --mini-sidebar-color: #222222;
    --sidebar-color: #222222;
    --sidebar-item-color: #999999;
    --sidebar-icon-color: #999999;
    --border-color:#333333;
    --button-color:#333333;
    --button-outline-color:#333333;
    --input-color:#333333;
    --tile-color: #222222;
    --tile-border-color:#222222;
    --tile-light-color: #444444;
    --text-color: #ffffff;
    --heading-color: #999999;
    --muted-text-color: #aaaaaa;
    --active-text-color: #ffffff;
    --icon-filter: invert(100%);
    --css-skeleton: linear-gradient(to right, #333 0%, #333 20%, #222 40%, #444 100%);
    --translucent-color: rgba(17,17,17,0.8);
    --active-color: #6c5dd3;
}

